<template>
  <div class="vendor-tabs">
    <div class="row">
      <div class="top-row w-100">
        <div class="create-new-btn shadow-sm" @click="createNewVendor">
          Create New
        </div>
        <div class="menu-pill-container shadow-sm">
          <div
            class="menu-pill"
            v-bind:class="activeTab === 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            Active Vendors
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            Contracted Vendors
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 2 ? 'active' : ''"
            @click="changeTab(2)"
          >
            Archived Vendors
          </div>
          <div
            class="menu-pill"
            v-bind:class="activeTab === 3 ? 'active' : ''"
            @click="changeTab(3)"
          >
            Fetch Vendors
          </div>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" align="center" v-model="activeTab">
      <b-tab active>
        <template v-if="activeTab === 0">
          <active-vendors-us ref="activeVendors" @editVendor="editVendor" />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 1">
          <contracted-vendors
            ref="contractedVendors"
            @unArchiveVendor="unArchiveVendor"
          />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 2">
          <archived-vendors
            ref="archivedVendors"
            @editVendor="editVendor"
            @unArchiveVendor="unArchiveVendor"
          />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="activeTab === 3">
          <fetch-vendors />
        </template>
      </b-tab>
    </b-tabs>
    <template v-if="vendorToEdit !== null">
      <edit-vendor-us
        ref="editVendorModal"
        @editedVendor="editedVendor"
        @closeEditVendor="closeEditVendor"
        :vendorToEdit="vendorToEdit"
      />
    </template>
    <template v-if="vendorToUnArchive !== null">
      <unarchive-store
        ref="unarchiveVendorModal"
        @unArchivedStore="unArchivedVendor"
        :vendorToEdit="vendorToUnArchive"
        @close="closeUnarchive"
      />
    </template>
  </div>
</template>

<script>
import ActiveVendorsUs from "@/view/pages/vendors/us/ActiveVendors";
import ContractedVendors from "@/view/pages/vendors/us/ContractedVendors";
import ArchivedVendors from "@/view/pages/vendors/us/ArchivedVendors";
import FetchVendors from "@/view/pages/vendors/us/FetchVendors";
import EditVendorUs from "@/view/pages/vendors/us/EditVendor";
import UnarchiveVendor from "@/view/pages/vendors/UnarchiveVendor";
export default {
  name: "VendorTabsUs",
  components: {
    EditVendorUs,
    ActiveVendorsUs,
    ArchivedVendors,
    FetchVendors,
    "unarchive-store": UnarchiveVendor,
    ContractedVendors
  },
  data: () => ({
    activeTab: 0,
    vendorToEdit: null,
    vendorToUnArchive: null
  }),
  computed: {},
  mounted() {},
  methods: {
    unArchiveVendor: function(vendor) {
      this.vendorToUnArchive = vendor;
      setTimeout(() => {
        this.$refs.unarchiveVendorModal.showModal();
      }, 100);
    },
    unArchivedVendor: function() {
      this.$refs.unarchiveVendorModal.closeModal();
      this.updateOption();
      setTimeout(() => {
        this.vendorToUnArchive = null;
      }, 100);
    },
    updateOption() {
      switch (this.activeTab) {
        case 0:
          this.$refs.activeVendors.updateVendors();
          break;
        case 1:
          this.$refs.contractedVendors.updateVendors();
          break;
        case 2:
          this.$refs.archivedVendors.updateVendors();
          break;
        default:
          this.$refs.activeVendors.updateVendors();
      }
    },
    closeUnarchive: function() {
      setTimeout(() => {
        this.vendorToUnArchive = null;
      }, 100);
    },
    editVendor: function(vendor) {
      this.vendorToEdit = vendor;
      setTimeout(() => {
        this.$refs.editVendorModal.showModal();
      }, 100);
    },
    changeTab(index) {
      this.activeTab = index;
    },
    closeEditVendor: function() {
      setTimeout(() => {
        this.vendorToEdit = null;
      }, 100);
    },
    editedVendor: async function() {
      this.updateOption();
      setTimeout(() => {
        this.vendorToEdit = null;
      }, 100);
    },
    createNewVendor() {
      this.$emit("new");
    }
  }
};
</script>

<style lang="scss" scoped>
.vendor-tabs {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;
    .create-new-btn {
      position: absolute;
      right: 15px;
      top: 0;
      background: #fff;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 10px;
      font-weight: 700;
    }
    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;

      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 200px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  .go-btn {
    color: #fff;
    background: #d8102a;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .sorting-options {
    .option-styles {
      display: grid;
      grid-template-columns: auto auto;
    }
    ::v-deep legend.col-form-label {
      font-weight: 600;
    }
  }
  ::v-deep .nav-tabs {
    display: none;
  }
}
</style>
