<template>
  <div class="vendors">
    <template v-if="region === 'US'">
      <vendor-tabs-us @new="createNewVendor" />
    </template>
    <template v-else>
      <vendor-tabs-pk @new="createNewVendor" />
    </template>
    <template v-if="!activeTab && processNewVendor">
      <template v-if="region === 'US'">
        <new-vendor-us
          ref="newVendorModal"
          @addedNewVendor="addedNewVendorUS"
        />
      </template>
      <template v-else>
        <NewVendor ref="newVendorModal" @addedNewVendor="addedNewVendorPK" />
      </template>
    </template>

    <template v-if="vendorToEdit !== null && region === 'PAKISTAN'">
      <EditVendor
        ref="editVendorModal"
        @editedVendor="editedVendor"
        :vendorToEdit="vendorToEdit"
        @resetRider="resetRider"
      />
    </template>

    <template v-if="vendorToUnArchive !== null">
      <unarchive-store
        ref="unarchiveVendorModal"
        @unArchivedStore="unArchivedVendor"
        :vendorToEdit="vendorToUnArchive"
        @close="closeUnarchive"
      />
    </template>
    <template v-if="secretPass !== ''">
      <RiderPass
        v-if="region === 'PAKISTAN'"
        ref="vendorPassModal"
        :riderPass="secretPass"
        @updateRiders="updateStoreCreated"
      />
      <RiderPass
        v-if="region === 'US'"
        ref="vendorPassModal"
        :riderPass="secretPass"
      />
    </template>
  </div>
</template>

<script>
import NewVendor from "@/view/pages/vendors/NewVendor";
import VendorTabsUs from "@/view/pages/vendors/us/VendorTabs";
import VendorTabsPk from "@/view/pages/vendors/pk/VendorTabs";
import NewVendorUS from "@/view/pages/vendors/us/NewVendor";
import EditVendor from "@/view/pages/vendors/EditVendor";
import UnarchiveVendor from "@/view/pages/vendors/UnarchiveVendor";
import ApiService from "@/core/services/api.service";
import RiderPass from "@/view/pages/riders/RiderPass";
import { mapState } from "vuex";
export default {
  name: "VendorParent",
  components: {
    VendorTabsUs,
    VendorTabsPk,
    NewVendor,
    EditVendor,
    "new-vendor-us": NewVendorUS,
    "unarchive-store": UnarchiveVendor,
    RiderPass
  },
  data() {
    return {
      activeTab: 0,
      vendorToEdit: null,
      processNewVendor: false,
      vendorToUnArchive: null,
      secretPass: ""
    };
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  },

  methods: {
    resetRider: function() {
      this.vendorToEdit = null;
    },
    createNewVendor: function() {
      this.processNewVendor = true;
      setTimeout(() => {
        this.$refs.newVendorModal.showModal();
      }, 100);
    },
    addedNewVendorPK: function(text = null) {
      if (text !== null) this.secretPass = text;
      ApiService.setHeader("content-type", "application/json");
      if (text === null) {
        this.$refs.LiveVendorsRef.updateVendors();
      }
      this.$refs.newVendorModal.closeModal();
      setTimeout(() => {
        this.processNewVendor = false;
        this.$refs.vendorPassModal.showModal();
      }, 100);
    },
    addedNewVendorUS: function(password) {
      if (password !== null) this.secretPass = password;
      ApiService.setHeader("content-type", "application/json");
      if (password === null) {
        this.$refs.LiveVendorsRef.updateVendors();
      }
      this.$refs.newVendorModal.closeModal();
      setTimeout(() => {
        this.processNewVendor = false;
        this.$refs.vendorPassModal.showModal();
      }, 100);
    },
    updateStoreCreated: function() {
      this.$refs.LiveVendorsRef.updateVendors();
      setTimeout(() => {
        this.secretPass = "";
      }, 100);
    },
    changeTab: function(index) {
      this.activeTab = index;
    },
    editVendor: function(vendor) {
      this.vendorToEdit = vendor;
      setTimeout(() => {
        this.$refs.editVendorModal.showModal();
      }, 100);
    },
    editedVendor: function(password) {
      ApiService.setHeader("content-type", "application/json");
      this.$refs.editVendorModal.closeModal();
      this.secretPass = password;
      setTimeout(() => {
        this.vendorToEdit = null;
        if (password) this.$refs.vendorPassModal.showModal();
      }, 100);

      if (this.activeTab === 0) {
        this.$refs.LiveVendorsRef.updateVendors();
      } else {
        this.$refs.archivedVendorsRef.updateVendors();
      }
    },
    unArchiveVendor: function(vendor) {
      this.vendorToUnArchive = vendor;
      setTimeout(() => {
        this.$refs.unarchiveVendorModal.showModal();
      }, 100);
    },
    closeUnarchive: function() {
      this.vendorToUnArchive = null;
    },
    unArchivedVendor: function() {
      this.$refs.unarchiveVendorModal.closeModal();
      this.$refs.archivedVendorsRef.updateVendors();
      setTimeout(() => {
        this.vendorToUnArchive = null;
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.vendors {
  .top-row {
    z-index: 96;
    display: flex;
    position: relative;
    justify-content: center;
    .create-new-btn {
      position: absolute;
      right: 15px;
      top: 0;
      background: #fff;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 10px;
      font-weight: 700;
    }
    .menu-pill-container {
      border-radius: 50px;
      overflow: hidden;

      .menu-pill {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        width: 200px;
        &.active {
          background: #dc1828;
          color: #fff;
        }
        color: #000;
      }
    }
  }
  .go-btn {
    color: #fff;
    background: #d8102a;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .sorting-options {
    .option-styles {
      display: grid;
      grid-template-columns: auto auto;
    }
    ::v-deep legend.col-form-label {
      font-weight: 600;
    }
  }
  ::v-deep .nav-tabs {
    display: none;
  }
}
</style>
