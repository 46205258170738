<template>
  <div class="active-vendors">
    <div class="row">
      <div class="col-lg-6 py-3">
        <h3>Archived Partners</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-4 sorting-options">
        <b-form-group label="Sort Vendors By">
          <b-form-radio-group
            v-if="region === 'US'"
            class="option-styles"
            v-model="sort"
            :options="sortOptions"
            name="radio-inline"
            @change="changeSort"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div
        class="
          col-4
          d-flex
          flex-column
          justify-content-between
          align-items-center
        "
      >
        <b-form-group>
          <b-form-radio-group
            id="btn-radios-1"
            v-model="sortDirection"
            :options="sortDirectionOptions"
            buttons
            name="radios-btn-default"
            @change="changeDirection"
          ></b-form-radio-group>
        </b-form-group>
        <form @submit.prevent="searchVendors">
          <div class="row d-flex align-items-center">
            <div class="col-9">
              <b-input
                type="text"
                v-model="searchText"
                placeholder="Search Vendor"
              />
            </div>
            <div class="col-3">
              <button class="go-btn" type="submit">Go</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-4 d-flex align-items-center justify-content-end py-3">
        <div class="row w-100">
          <span class="col-4 d-flex align-items-center">Partners Per Page</span>
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8"
            @change="perPageChange"
          ></b-form-select>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center lead text-danger">
        <transition name="fade-in-up">
          <span v-if="archivingError !== ''">{{ archivingError }} </span>
        </transition></b-col
      >
    </b-row>
    <b-overlay :show="storesFetchingOverlay || archiving">
      <template v-if="networkError !== null">
        <div class="text-danger text-center display-4 p-4">Network Error</div>
        <div class="text-danger text-center lead pb-4">
          {{ networkError }}
        </div>
      </template>
      <template v-else>
        <template v-if="activeRows < 1">
          <div class="text-warning p-4 bg-light text-center">
            No active Partners are present in the records at the moment.
          </div>
        </template>
        <b-row>
          <div
            class="col-6 d-flex"
            v-for="(iVendor, index) in vendors"
            :key="iVendor.employeeNumber"
          >
            <SingleVendor
              :singleVendor="iVendor"
              @editVendor="editVendor"
              @update="updateVendors"
              :index="index"
              @archiveAction="unArchiveVendor(index)"
            />
          </div>
        </b-row>
      </template>
    </b-overlay>
    <div class="d-flex justify-content-center">
      <b-pagination
        v-model="activePage"
        :total-rows="activeRows"
        :per-page="perPage"
        align="fill"
        @change="changePage"
        last-number
        first-number
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import SingleVendor from "@/view/pages/vendors/SingleVendor";
import { mapState } from "vuex";
import { GET_STORES } from "@/core/services/store/stores.module";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
export default {
  data() {
    return {
      archivingError: "",
      archiving: false,
      activePage: 1,
      perPage: 10,
      currentPage: 0,
      sort: "createdAt",
      sortOptions: [
        { text: "Created", value: "createdAt" },
        { text: "Name", value: "name" }
      ],
      sortDirection: "-",
      sortDirectionOptions: [
        {
          text: "Ascending",
          value: "+",
          html: '<i class="fa fa-sort-amount-up"/>'
        },
        {
          text: "Descending",
          value: "-",
          html: '<i class="fa fa-sort-amount-down"/>'
        }
      ],
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ],
      searchText: ""
    };
  },
  computed: {
    ...mapState({
      vendors: state => state.stores.archived.stores,
      activeRows: state => state.stores.archived.count,
      storesFetchingOverlay: state => state.stores.archived.fetching,
      networkError: state => state.stores.archived.error,
      region: ({ region }) => region.region
    })
  },
  methods: {
    editVendor: function(index) {
      const vendor = this.vendors[index];
      this.$emit("editVendor", vendor);
    },
    changeSort: function() {
      this.updateVendors();
    },
    changeDirection: function() {
      this.updateVendors();
    },
    searchVendors: function() {
      this.updateVendors();
    },
    perPageChange() {
      this.updateVendors();
    },
    changePage(val) {
      this.updateVendors(val);
    },
    getQueryOptions(cp = -1) {
      const { currentPage, perPage, searchText, sort, sortDirection } = this;
      let cpp = currentPage;
      if (cp !== -1) {
        this.currentPage = cp;
        cpp = cp - 1;
      }
      return {
        type: "archived",
        offset: cpp * perPage,
        limit: perPage,
        search: searchText,
        sort: `${sortDirection}${sort}`
      };
    },
    updateVendors: async function(cp = -1) {
      const options = this.getQueryOptions(cp);
      await this.$store.dispatch(GET_STORES, options);
    },
    archiveVendor({ id, name }) {
      this.archiving = true;
      const token = JwtService.getToken();
      const dataToSend = {
        storeId: id,
        productsList: [],
        token
      };
      ApiService.post("/store/archiveStore", dataToSend)
        .then(({ data: res, error }) => {
          if (res.success) {
            this.archivingError = `${name} was archived.`;
            setTimeout(() => {
              this.archivingError = "";
            }, 5500);
            setTimeout(() => {
              this.updateVendors();
              this.archiving = false;
            }, 100);
          } else {
            this.archivingError = `Archive Error: ${error.message}`;
            setTimeout(() => {
              this.archivingError = "";
            }, 5500);
            this.archiving = false;
          }
        })
        .catch(({ data }) => {
          this.archivingError = `Archive Error: ${data.message} on ${data.url}`;
          this.archiving = false;
          setTimeout(() => {
            this.archivingError = "";
          }, 5500);
        });
    },
    unArchiveVendor(index) {
      const vendor = this.vendors[index];
      this.$emit("unArchiveVendor", vendor);
    }
  },
  mounted() {
    this.updateVendors();
  },
  components: {
    SingleVendor
  }
};
</script>
<style lang="scss" scoped>
.active-vendors {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: space-evenly;
  .vendors-list {
    min-height: 100px;
  }
  .go-btn {
    color: #fff;
    background: #d8102a;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
  }
}
</style>
